import React, { useEffect } from 'react';
import ContactUsCard from '../../Components/ContactUsCard/ContactUsCard';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgContactus from "../../assets/ContactLocation/Contactus.jpg"
import imgUSATexas1 from '../../assets/ContactLocation/USATexas1.png'
import imgUSACalifornia2 from '../../assets/ContactLocation/USACalifornia2.png'
import imgUSAWashington3 from '../../assets/ContactLocation/USAWashington3.png'
import imgUSAMentor4 from '../../assets/ContactLocation/USAMentor4.png'
import imgUSAVictoria5 from '../../assets/ContactLocation/USAVictoria5.png'
import imgUSANewBraunfels6 from '../../assets/ContactLocation/USANewBraunfels6.png'
import imgUSATacoma7 from '../../assets/ContactLocation/USATacoma7.png'


const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const units = [
        {
            name: 'HANSA-FLEX USA, Houston, Texas',
            location: '10821 Train CT, Houston, TX 77041, United States',
            telephone: '+1 713 466 6673',
            fax: "+1 713 466 8030",
            mail: 'USTXHSALES@hansa-flex.com',
            image: imgUSATexas1
        },
        {
            name: 'HANSA-FLEX USA Ontario, California',
            // description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            location: '12005 Jack Benny Dr Ste 103, Rancho Cucamonga, CA 91739, United States',
            telephone: ' +1 909 948 7060 ',
            fax: "+1 909 948 7025",
            mail: 'USCARSALES@hansa-flex.com',
            image: imgUSACalifornia2
        },
        // {
        //     name: 'HANSA-FLEX USA Auburn, Washington',
        //     // description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        //     location: '2222 W. Valley Hwy N, Ste 170, 98001 ',
        //     telephone: '+1 253 886 5606',
        //     fax: "+1 253 886 5609",
        //     mail: 'USWAASALES@hansa-flex.com',
        //     image: imgUSAWashington3
        // },
        {
            name: 'HANSA-FLEX USA Mentor, Ohio',
            // description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            location: '7561 Tyler Blvd Ste 1, Mentor, OH 44060, United States',
            telephone: '+1 440 946 4673',
            fax: "+1 440 946 3393",
            mail: 'USOHMSALES@hansa-flex.com',
            image: imgUSAMentor4
        },
        {
            name: 'HANSA-FLEX USA Victoria, Texas',
            // description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            location: '122 John Stockbauer Dr, Victoria, TX 77901, United States',
            telephone: '+1 361 580 1931',
            fax: "+1 361 580 1941",
            mail: 'USTXVSALES@hansa-flex.com',
            image: imgUSAVictoria5
        },
        {
            name: 'HANSA-FLEX USA New Braunfels, Texas',
            // description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            location: '3931 N I-35 North Frontage Rd, New Braunfels, TX 78132, United States',
            telephone: '+1 830 420 6674',
            fax: "+1 440 946 3393",
            mail: 'USTXKSALES@hansa-flex.com',
            image: imgUSANewBraunfels6
        },
        {
            name: 'HANSA-FLEX USA Tacoma, Washington',
            // description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            location: '2406 Port of Tacoma Rd, Tacoma, WA 98421, United States',
            telephone: '+1 253 886 5606',
            fax: "+1 253 886 5609",
            mail: 'USWATSALES@hansa-flex.com',
            image: imgUSATacoma7
        },
    ];

    return (
        <div className="">
            <div>
                <CenterStage
                    imageSrc={imgContactus}
                    title={"Get in touch with HANSA-FLEX USA"}
                    paragraph={"HANSA-FLEX USA, a vital part of the esteemed HANSA-FLEX Group, continues its legacy of excellence across multiple locations in the United States. Originally operating as Hatec International Inc., each branch is dedicated to providing top-tier hydraulic solutions and services, ensuring customers have access to the best in the industry. With a strong presence across various states, HANSA-FLEX USA remains committed to supporting the hydraulic needs of industries nationwide."} />
            </div>
            <div className=''>
                <ContactUsCard units={units} />
            </div>
        </div>
    );
};

export default ContactUs;
