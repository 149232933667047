import React, { useEffect } from 'react';
import HFTab from '../../Components/HfTabs/HfTabs';
import CenterStage from '../../Components/CenterStage/CenterStage';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../Components/ImageCard/ImageCard';
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../assets/mark.png';

import imgMobileService from '../../assets/Services/MobileService/MobileService.jpg'
import img360Hose from '../../assets/Services/MobileService/360HoseManagement.jpg'
import imgHFNeverFar from '../../assets/Services/MobileService/HFNeverFar.jpg'
import imgOilCareEasy from '../../assets/Services/MobileService/OilCareEasy.jpg'
import imgDispatch from '../../assets/Services/MobileService/Dispatch.jpg'
import imgDescription from '../../assets/Services/MobileService/Description.jpg'
import imgSpareParts from '../../assets/Services/MobileService/SpareParts.jpg'
import imgDocumentation from '../../assets/Services/MobileService/Documentation.jpg'
import imgHoseManufacturing from '../../assets/Services/MobileService/HoseManufacturing.jpg'

//TODO: Carousal
//TODO: video
//TODO: Textposter

const MobileRapidHydraulicService = () => {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    // features card carousel data
    const featuresData = [
        {
            title: "By phone",
            description: "You can contact the emergency service through the toll-free hotline +1 713 466 6673.",
            image: imgMark
        },
        {
            title: "Contact us through the mobile app",
            description: "You can contact the emergency service for free through the HANSA-FLEX app.",
            image: imgMark
        },
        {
            title: "Online form",
            description: "You can request support from the mobile emergency service directly through our online form.",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Digital dispatch",
            description: "Our experienced dispatchers are available 24/7 for you. Using high-capacity software, they always have an overview of all orders, the expected route of each vehicle, and their current location. With a click of the mouse, they can reveal the location of the mobile service vehicle that can reach you as quickly as possible. Dispatchers send all important information about the request to the service technician, which appears on the vehicle's tablet before its arrival at the site, so that the work can be efficiently completed. This way, the emergency mobile service technician is fully prepared before the vehicle arrives at your premises.",
            image: imgDispatch
        },
        {
            title: "Exact description",
            description: "We need your support so that processes can be handled as quickly as possible. Make sure the information you provide us about the requested service is as accurate as possible:",
            listItems: ["What are the contact names?", "Which parts or components are defective?", "Are there photos of the installation situation?", "Which replacement parts are likely needed?"],
            image: imgDescription
        },
        {
            title: "Perfectly tailored",
            description: "Mobile service vehicles carry a stock of spare parts and wear parts most frequently needed for their respective field of use. The software used in our Rapid Hydraulic Service mobile service is directly linked to our Materials Management team. Thus, the dispatcher can already see which spare parts are on board each vehicle when the service order is placed. If other parts are needed for work on site, the mobile service vehicle can make a planned intermediate stop on the route at the nearest HANSA-FLEX branch.",
            image: imgSpareParts
        },
        {
            title: "Mobile hose manufacturing",
            description: "The vehicles of the Rapid Hydraulic Service mobile fleet are workshops on wheels. They carry all the equipment needed for the manufacture of custom hydraulic hose lines, including cutting, stripping, and crimping machines. The generator under the floor provides a reliable and independent power source at construction sites or in remote areas and allows technicians to work at night. The 400-volt generator makes the workshop on wheels completely independent of on-site conditions.",
            image: imgHoseManufacturing
        },
        {
            title: "Comprehensive documentation",
            description: "During work, service technicians take high-resolution photos of damage and newly installed components with their tablets and archive all information in the service report. This can be signed directly on-site by the customer on the tablet, who will receive the report along with the invoice by email. If the customer uses the My.HANSA-FLEX hose management portal, the service technician can store details of hose line replacement directly in the system.",
            image: imgDocumentation
        },
    ];

    const tabTitles = [
        { title: "Dispatch", eventKey: "Dispatch" },
        { title: "Description", eventKey: "Description" },
        { title: "Replacement Parts", eventKey: "Replacement Parts" },
        { title: "Hose Manufacturing", eventKey: "Hose Manufacturing" },
        { title: "Documentation", eventKey: "Documentation" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgMobileService}
                    title={"READY TO ACT AT ANY MOMENT: THE RAPID HYDRAULIC SERVICE"}
                    paragraph={"When hydraulic components fail unexpectedly, quick action is needed. With 180 vehicles throughout Germany, our Rapid Hydraulic Service mobile is always at your disposal, available 24/7, 365 days a year, to minimize downtime. Our well-trained and experienced service technicians are with you within an hour. They rectify faults, install replacement parts, and manufacture custom hose lines on-site, in their onboard workshop."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"SENDING TO THE MOBILE EMERGENCY SERVICE IS QUICK AND EASY"}
                    subtitle={"The mobile emergency service is there for you when things go wrong. Contact us and our customer service center takes care of all planning and coordinates with service technicians."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"EFFICIENT HANDLING FROM DISPATCH TO DOCUMENTATION"}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            {/* TODO here */}

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />

                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={img360Hose}
                                title="360° Hose Management"
                                desc={"The necessary parts can be quickly and reliably identified using HANSA-FLEX's X-CODE. The six-digit code is permanently attached to the hose lines and is easy to read."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHFNeverFar}
                                title="HANSA-FLEX is never far away"
                                desc={"We have a highly integrated global network of branches. That's how we ensure that support from HANSA-FLEX experts is never far away."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgOilCareEasy}
                                title="Taking care of oil has never been easier"
                                desc={"HANSA-FLEX's Fluid Service performs oil analyses in the laboratory or directly on-site, carries out oil cleaning, and provides guidance on all hydraulic oil-related issues."} /></Col>
                        </Row>
                    </Container>
                </div>

            </div>

        </div>
    )

}

export default MobileRapidHydraulicService